<template>
  <v-container fluid>

    <v-container fluid :class="$vuetify.breakpoint.lgAndUp? 'px-6 py-6' : 'px-1 py-1'">

      <v-alert
      outlined
      border="top"
      type="info"
      color="text"
      style="background-color:white !important;"
      >
          <span class="black--text">
            <h3 class="mb-2">Description for Request API Module</h3>
            <v-icon class="mr-2 mb-1">mdi-clipboard-text-outline</v-icon> This module allows Registered Users to request data according to the list of API Tables specified by JMG using the provided online API Sharing Request Form.<br>
            <v-icon class="mr-2 mb-1">mdi-email-outline</v-icon>  The system Administrator will receive a notification (via email in the Administration Module) and confirm the details of the Data Sharing Online Form (API) before the API link sent to the user's email.
          </span>
      
      </v-alert>

      <v-row :class="$vuetify.breakpoint.lgAndUp? 'px-6 py-6 d-flex' : 'px-1 py-1 d-flex'">
         <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn text v-bind="attrs" v-on="on">
              <v-icon class="mr-2 mb-1 text--text">mdi-help-circle</v-icon>
            </v-btn>
          </template>
          <span>Instruction</span>
        </v-tooltip>

        <v-btn class="mr-4 white--text" color="primary" @click="dialogStationList = !dialogStationList">
          <v-icon class="mr-2 mb-1 white--text">mdi-format-list-bulleted</v-icon>
          Station List
        </v-btn>

        <v-btn color="primary" class="white--text" @click="dialogDisplayForm = true">
          <v-icon class="mr-2 mb-1 white--text">mdi-file-document-edit-outline</v-icon>
          Application Form
        </v-btn>
      </v-row>
    
      <v-card class="mt-3">
        <v-card-title class="black--text px-6">
          <v-icon class="mr-3 text--text">mdi-format-list-bulleted</v-icon> Request API List
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            outlined
            dense
            single-line
            class="shrink"
            hide-details
          ></v-text-field>

        </v-card-title>

        <v-data-table
          :headers="theadMain"
          :items="tbodyMain"
          :items-per-page="5"
          class="elevation-1 px-6 headerDtSarawak"
          :search="search"
          :loading="tbodyMain.length != 0 ? false : true"
        >
          <template v-slot:item.status="{ item }">
            <v-chip :color="item.status === 'APPROVE' ? 'green' : item.status === 'PENDING'? 'orange' : 'red' " dark>
             {{ item.status }}
            </v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <template>
              <v-icon dense class="mr-2 primary--text" @click="displayDetails(item)"
                >mdi-text-box</v-icon
              >
            </template>
          </template>
        </v-data-table>
      </v-card>


-
      <!---- DIALOG DISPLAY DETAILS ---->
      <v-dialog
      v-model="dialogDisplayDetails"
      persistent
      scrollable
      max-width="900px"
      >
        <v-card>

          <v-card-title class="card-header">
            <span class="text-h5 white--text">Information</span>
            <v-spacer></v-spacer>
            <v-icon
            class="white--text"
            style="cursor:pointer;"
            @click="dialogDisplayDetails = false"
            >
              mdi-close-thick
            </v-icon>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-container ref="infoDetailsAPIRequestList" style="height: 790px;">

              <v-card-title>
                <span class="text-h6 cust-subtitle">Applicant Information</span>
              </v-card-title>

              <v-card-text>
                  <v-row>
                    <v-col cols="8">

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Full Name
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span> <!-- {{tbodyInfoDetails.fullname? '' : tbodyInfoDetails.fullname}} -->
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Ministry / Agency / NGO / IPT
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.agencyrequest}}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Phone Number
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span> <!-- {{tbodyInfoDetails.phone? '' : tbodyInfoDetails.phone}} -->
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Email
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.email}}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Designation / Profession
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.position}}
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
              </v-card-text>

              <v-card-title>
                <span class="text-h6 cust-subtitle">About Data Sharing</span>
              </v-card-title>

              <v-card-text>
                  <v-row>
                    <v-col cols="8">

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Purpose of Use Web API
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span> {{tbodyInfoDetails == null ? '' : tbodyInfoDetails.purpose}}
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col class="cust-subtitle-key">
                          Supporting Document
                        </v-col>
                        <v-col class="cust-subtitle-value">
                          <span class="font-weight-bold primary--text">:</span>
                          <v-icon 
                          class="primary--text"
                          style="cursor:pointer;"
                          @click="openDocument(tbodyInfoDetails.requestdoc)"
                          >
                            mdi-file-document
                          </v-icon>
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
              </v-card-text>

              <v-card-title>
                <span class="text-h6 cust-subtitle">Water Quality Station</span>
              </v-card-title>

              <v-card-text>
                  <v-row>
                    <v-col class="px-0">

                      <v-simple-table class="cust-simple-table-details-form">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th 
                              v-for="(item,i) in theadInfoDetails"
                              :key="i"
                              class="text-center"
                              style="text-wrap: nowrap !important;"
                              >
                                {{item}}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.agencyrequest }}</td>
                              <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.api }}</td>
                              <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.location }}</td>
                              <td class="text-center" style="text-align:center;">
                                <!-- {{ tbodyInfoDetails.status }} -->
                                <v-chip
                                :color="tbodyInfoDetails.status == 'APPROVE'? 'success' : tbodyInfoDetails.status == 'PENDING'? 'orange' : 'error'"
                                dark
                                >
                                  {{tbodyInfoDetails.status}}
                                </v-chip>
                              </td>
                              <td class="text-center" style="text-align:center;">{{ tbodyInfoDetails.dateexpire }}</td>
                              <td class="text-center" style="text-align:center;">
                                <v-icon
                                class="primary--text"
                                @click="openDocument(tbodyInfoDetails.url)"
                                >mdi-file-code</v-icon>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>

                    </v-col>
                  </v-row>
              </v-card-text>

            </v-container>
          </v-card-text>

          <!-- <v-divider></v-divider> -->

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            class="mr-3"
            color="primary"
            @click="printBorang()"
            >
              Print
            </v-btn>
          </v-card-actions> -->

        </v-card>
      </v-dialog>

      <!-- STATION LIST -->  
      <v-dialog 
      v-model="dialogStationList" 
      max-width="80%"
      persistent
      scrollable 
      >
        <v-card class="card-bg">

          <v-card-title class="primary white--text justify-center dark card-header">
            <span class="text-h5 white--text">Station List</span>
            <v-spacer></v-spacer>
            <v-icon
            class="white--text"
            style="cursor:pointer;"
            @click="dialogStationList = false"
            >
              mdi-close-thick
            </v-icon>
            <!-- <v-icon class="mr-2 white--text">mdi-file-document-edit-outline</v-icon> <h3>Station List</h3> -->
          </v-card-title>


          <v-card-text v-if="viewContentPopupStationList == 'station list'">
            <v-container>

              <v-data-table
              :headers="theadStation"
              :items="tbodyStation"
              :items-per-page="5"
              class="elevation-1 headerDtSarawak my-4"
              >
              
                <template v-slot:item.details="{ item }">
                  <template>
                    <v-icon dense class="mr-2 primary--text" @click="showStationParameter(item)">
                      mdi-view-list
                    </v-icon>
                  </template>
                </template>
              
              </v-data-table>

            </v-container>
          </v-card-text>

          <v-card-text v-else-if="viewContentPopupStationList == 'station parameter'">
            <v-container>

              <v-row class="mr-1">
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="viewContentPopupStationList = 'station list'">
                  <v-icon class="mr-2 mb-1 white--text">mdi-arrow-left-top</v-icon>
                  Back
                </v-btn>
              </v-row>

              <v-data-table
              :headers="theadParams"
              :items="tbodyParams"
              :items-per-page="10"
              class="elevation-1 headerDtSarawak my-4"
              ></v-data-table>

            </v-container>
          </v-card-text>

          <!-- <v-divider></v-divider> -->

          <!-- <v-card-actions class="justify-end" style="background-color:white;">
            <v-btn
              @click="dialogStationList = !dialogStationList"
              color="error"
            >
              CLOSE
            </v-btn>
          </v-card-actions> -->

        </v-card>
      </v-dialog>

       <!-- -------- DIALOG DISPLAY FORM ------------ -->
      <v-dialog
      v-model="dialogDisplayForm"
      persistent
      scrollable
      max-width="900px"
      >
        <v-card>

          <v-card-title class="card-header">
            <span class="text-h5 white--text">Application Form</span>
            <v-spacer></v-spacer>
            <v-icon
            class="white--text"
            style="cursor:pointer;"
            @click="closeDialogDisplayForm()"
            >
              mdi-close-thick
            </v-icon>
          </v-card-title>

          <v-divider></v-divider>

          <v-card-text>
            <v-container style="height: 790px;">

              <v-card-title>
                <span class="text-h6 cust-subtitle">Applicant Information</span>
              </v-card-title>

              <v-card-text>
                  <v-row>
                    <v-col cols="12">

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Full Name
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-text-field
                          v-model="formRequest.fullname"
                          outlined
                          dense
                          hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Ministry / Agency / NGO / IPT
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-text-field
                          v-model="formRequest.agencyrequest"
                          outlined
                          dense
                          hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Phone Number
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-text-field
                          v-model="formRequest.phoneNo"
                          outlined
                          dense
                          hide-details
                          @keypress="isNumber($event)"
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Email
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-text-field
                          v-model="formRequest.email"
                          outlined
                          dense
                          hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Designation / Profession
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-text-field
                          v-model="formRequest.position"
                          outlined
                          dense
                          hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
              </v-card-text>

              <v-card-title>
                <span class="text-h6 cust-subtitle">About Data Sharing</span>
              </v-card-title>

              <v-card-text>
                  <v-row>
                    <v-col cols="12">

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Purpose of Use Web API
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-text-field
                          v-model="formRequest.purpose"
                          outlined
                          dense
                          hide-details
                          ></v-text-field>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Supporting Document
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-file-input
                          v-model="modelFileSupportDocReqAPI"
                          outlined
                          dense
                          hide-details
                          show-size
                          @change="uploadSupportingDoc()"
                          ></v-file-input>
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
              </v-card-text>

              <v-card-title>
                <span class="text-h6 cust-subtitle">Water Quality Station</span>
              </v-card-title>

              <v-card-text>
                  <v-row>
                    <v-col cols="12">

                      <v-row>
                        <v-col cols="4" class="cust-subtitle-key d-flex align-center">
                          Station
                        </v-col>
                        <v-col class="cust-subtitle-value d-flex align-center">
                          <span class="font-weight-bold primary--text mr-2">:</span>
                          <v-autocomplete
                          :items="stationList"
                          v-model="formRequest.selected_station"
                          outlined
                          dense
                          hide-details
                        ></v-autocomplete>
                        </v-col>
                      </v-row>

                    </v-col>
                  </v-row>
              </v-card-text>

            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            class="mr-3"
            color="primary"
            :disabled="formRequest.fullname == '' || formRequest.agencyrequest == '' || formRequest.phoneNo == '' || formRequest.email == '' || formRequest.position == '' || formRequest.purpose == '' || formRequest.requestdoc == '' || formRequest.selected_station == ''"
            @click="submitReqFormAPI()"
            >
              Submit
            </v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>


      <!-- DIALOG SUCCESS/FAILED SUBMIT -->
      <div class="text-center">
          <v-dialog
          v-model="dialogSuccessFailed"
          width="500"
          >
          <v-card>
              <v-card-title class="text-h5 white--text" :class="{'primary': modeSubmitSuccess == true, 'red': modeSubmitSuccess == false}">
                Request API 
                <v-icon v-if="modeSubmitSuccess == true" style="color:white;margin-left:15px;">mdi-check-circle</v-icon>
                <v-icon v-else style="color:white;margin-left:15px;">mdi-alert</v-icon>
              </v-card-title>

              <v-card-text style="padding:20px 24px;font-weight:bold;">
                <span v-if="modeSubmitSuccess == true">
                  The API Request has successfully send.
                </span>
                <span v-if="modeSubmitSuccess == false">
                  Your request has failed. Please go back and try again.
                </span>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
              color="primary"
              @click="dialogSuccessFailed = false;closeDialogDisplayForm()"
              >
                  OK
              </v-btn>
              </v-card-actions>
          </v-card>
          </v-dialog>
      </div>
    
    </v-container>
  </v-container>
</template>

<script>
import axios from "axios";
import appMixins from "@/mixins/globalMixins";
// -- import something here --

export default {
  data: () => ({

    pathModul: [
      {
        text: "Home",
        disabled: false,
        href: "/Mapviewer",
      },
      {
        text: "Request API List",
        disabled: true,
        href: "/requestAPI/requestApiList",
      },
    ],
    search: "",
    statusForm: ["Lulus", "Tolak"],
    formStatus: "Lulus",
    date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
    tabs_Telemetry: null,

    theadMain: [
      { text: "REFERENCE NO.", value: "api", align: "start", sortable: false, },
      { text: "EMAIL", value: "email" },
      { text: "REQUEST DATE & TIME", value: "requestdate" },
      { text: "STATUS", value: "status" },
      { text: "APPROVED DATE & TIME", value: "dateupdated" },
      { text: "FORM", value: "actions", align: "center", sortable: false },
    ],
    tbodyMain: [],

    //---- Dialog ----
    dialogDisplayDetails: false,
    dialogDisplayForm: false,
    dialogSuccessFailed: false,
    dialogStationList: false,

    //---- Details ----
    theadInfoDetails: [
      "Agency",
      "Station ID",
      "Location",
      "Status",
      "Expired Date",
      "API Link",
    ],
    tbodyInfoDetails: null,

    //---- Station ----
    viewContentPopupStationList: "station list",
    theadStation: [
      { text: "Station ID", value: "STATION_ID" },
      { text: "Location", value: "LOCATION" },
      { text: "State Name", value: "STATE_NAME" },
      { text: "Parameter", value: "details", align: "center", sortable: false },
    ],
    tbodyStation: [],
    paramlist: [],
    theadParams: [
      { text: "Acronym", value: "acronym" },
      { text: "Parameter", value: "fullname" },
      { text: "Unit", value: "unit" },
    ],
    tbodyParams: [],

    //---- Form ----
    formRequest: {
      fullname: "",
      agencyrequest: "",
      phoneNo: "",
      email: "",
      position: "",
      purpose: "",
      requestdoc: "",
      selected_station: "",
    },
    modelFileSupportDocReqAPI: null,
    originalStationList: [],
    stationList: [],
    modeSubmitSuccess: true,
    

    
    checkbox: true,
  }),

  mounted() {
    this.getParamList();
    this.getStationList();
    this.getAllUser();
  },

  methods: {

    getParamList() {
      axios
        .get(this.globalUrl + "mqm2/miqims/params", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {

          let data = response.data
          this.paramlist = data

        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getStationList() {
      axios
        .get(this.globalUrl + "mqm2/miqims/stations", {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {

          let data = response.data

          this.originalStationList = data
          this.tbodyStation = data;

          for(let i in data){
            this.stationList.push(data[i].STATION_ID + " - " + data[i].LOCATION)
          }

        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getAllUser() {
      axios
        .get(this.globalUrl + `mqm2/apim/allapis?startreqdate=2023-01-01&endreqdate=2023-12-12`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          let data = response.data;

          for(let i in data){
            data[i].requestdate = this.useConvertDisplayDatetime(data[i].requestdate);
            data[i].dateupdated = this.useConvertDisplayDatetime(data[i].dateupdated);
            data[i].dateexpire = this.useConvertDisplayDate(data[i].dateexpire.split("T")[0]);
          }

          this.tbodyMain = data;
          console.log("here", this.tbodyMain);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    displayDetails(item) {
      
      
      console.log("detailDisplay", item);
      this.tbodyInfoDetails = item;

      this.dialogDisplayDetails = true;
    },

    openDocument(url){
      window.open(url, "_blank");
    },

    showStationParameter(data){

      console.log(data);

      let databodyParam = [];
      let keys = Object.keys(data)
      for(let i in this.paramlist){
        for(let x in keys){
          if(keys[x].includes("_ACTIVE")){
            if(data[keys[x]] != null){
              if(keys[x].split("_ACTIVE")[0] == this.paramlist[i].reading){
                databodyParam.push(this.paramlist[i])
              }
            }
          }
        }
      }

      this.tbodyParams = databodyParam;

      this.viewContentPopupStationList = 'station parameter';

    },

    closeDialogDisplayForm(){

      this.dialogDisplayForm = false;

      this.formRequest = {
        fullname: "",
        agencyrequest: "",
        phoneNo: "",
        email: "",
        position: "",
        purpose: "",
        requestdoc: "",
        selected_station: "",
      };
      this.modelFileSupportDocReqAPI = null;

    },

    uploadSupportingDoc(){

      if(this.modelFileSupportDocReqAPI != undefined){

        let formData = new FormData();
        formData.append("files", this.modelFileSupportDocReqAPI);

        axios.post(this.globalUrl+`mqm2/miqims/upload`,formData, {
          headers: {
            Authorization: "Bearer " + this.$store.getters.user.token,
          },
        })
        .then((response) => {
          
          let data = response.data;
          console.log(data);
          this.formRequest.requestdoc = data.filepath;
          console.log(this.formRequest);


        })
        .catch((error) => {
          console.log(error.response);
        });

      }
      else{
        this.formRequest.requestdoc = "";
        console.log(this.formRequest);
      }

      

    },

    submitReqFormAPI() {

      // let json = {
      //     "email": this.email,
      //     "position": this.designation,
      //     "api": "/apim/JMG01,/apim/JMG02",
      //     "purpose": this.purpose,
      //     "agencyrequest":  this.agency,
      //     "type": this.purpose,
      //     "requestdoc": "https://www.wcofun.net/logo.gif",
      //     "approvedoc": "https://www.wcofun.net/logo.gif"
      // };

      console.log(this.formRequest);

      axios.post(this.globalUrl+"mqm2/apim/addapi", this.formRequest, {
        headers: {
          Authorization: "Bearer " + this.$store.getters.user.token,
        },
      })
      .then((response) => {
        console.log(response.data);
        // alert("Success");
        this.modeSubmitSuccess = true;

        this.dialogSuccessFailed = true;
        
      })
      .catch((error) => {
        console.log(error.response);
        this.modeSubmitSuccess = false;

        this.dialogSuccessFailed = true;
        // alert("Failed")

      });




    },

     
  },
};
</script>

<style lang="scss">
@import "~scss/main";

.pathModuls {
  padding-bottom: 0px;
}

.pathModuls > li:nth-child(3) > a {
  color: black !important;
}

.background {
  background-color: whitesmoke;
  height: 100%;
  width: 100%;
  top: 0;
  background-size: cover;
  top: 0;
  right: 0;
}

.header-style > div > table > thead{
  background-color: whitesmoke !important;
}

.cust-subtitle{
  color: $primary;
  font-weight: bold;
}

.cust-subtitle-key{
  color: $primary;
  font-weight: 600;
}

.cust-subtitle-value{
  color: black;
}

</style>
